
 /* Style Definitions */
 .MsoNormal {
    margin-bottom: 0cm;
    line-height: 12.0pt;
	color: #686868;
	display: grid;
	grid-template-columns: auto;
    grid-template-rows: auto;
	padding: 50px;
	padding-left: 100px;
	padding-right: 50px;
	margin-left: 10%;
	margin-right: 10%;
	li  {margin-top: 20px;}
    li  {margin-bottom: 20px;}
    ul  {margin-bottom: 10px;}
    ul  {margin-top: 0px;}



  }
.MsoNormal > div {
	text-align: center;
	padding: 20px 0;
	font-size: 30px;
  }
  p.MsoNormal, div.MsoNormal
  {margin-top:0cm;
  margin-right:0cm;
  margin-bottom:8.0pt;
  margin-left:0cm;
  line-height:125%;
  }


  



 